import React, { FunctionComponent } from "react";
import { toHTML } from "lib/string";
import { StyledDescription } from "./description.styles";
import { useView } from "hooks/view";
import { useTheme } from "hooks/theme";

export interface DescriptionProps {
  children: any;
  className?: string;
}

const Description: FunctionComponent<DescriptionProps> = ({
  children,
  className,
}) => {
  const view = useView();
  const theme = useTheme();
  const escaped = typeof children === "string" ? toHTML(children) : children;
  return (
    <StyledDescription $theme={theme} $view={view} className={className}>
      {escaped}
    </StyledDescription>
  );
};

export default Description;
