import React, { Fragment, FunctionComponent, useState } from "react";
import Logo from "./logo";
import Link from "./link";
import Collapse from "./collapse";
import Toggle from "./toggle";
import { useTranslations } from "hooks/translations";
import { useRouter } from "next/router";
import { StyledNavbar } from "./navbar.styles";
import {
  createEmbeddedQuestionnaireState,
  getQuestionnaireUrl,
} from "lib/questionnaire";
import Progress from "./progress";
import { useTheme } from "hooks/theme";
import { EmbeddedQuestionnaireVersion } from "models/questionnaire";

export interface NavProps {
  className?: string;
  logo?: boolean;
  logoAsLink?: boolean;
  links?: boolean;
  progress?: boolean;

  // this one overrides the style from
  transparent?: boolean;
}

const Navbar: FunctionComponent<NavProps> = ({
  className,
  logo = true,
  logoAsLink = true,
  links = false,
  progress = false,
  transparent = false,
}) => {
  const t = useTranslations();
  const theme = useTheme();
  const [collapsed, toggleCollapsed] = useState(true);

  const router = useRouter();
  const url = getQuestionnaireUrl(t, EmbeddedQuestionnaireVersion);

  // hide logo, if ?hf=1 is passed
  if (router.query["hf"]) {
    return null;
  }

  const handleClick = () => {
    createEmbeddedQuestionnaireState(t, router);
  };

  return (
    <StyledNavbar
      className={className}
      $transparent={transparent}
      $theme={theme}
    >
      {logo && <Logo link={logoAsLink} />}
      {progress && <Progress />}
      {links && (
        <Fragment>
          <Collapse collapsed={collapsed}>
            <Link href={url} onClick={() => handleClick()}>
              {t("menu_item1")}
            </Link>
            <Link href={t("/login")}>{t("menu_item2")}</Link>
            <Link href={t("/manage-your_plan")} desktop={false}>
              {t("menu_item3")}
            </Link>
          </Collapse>
          <Toggle collapsed={collapsed} onToggle={toggleCollapsed} />
        </Fragment>
      )}
    </StyledNavbar>
  );
};

export default Navbar;
