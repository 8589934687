import { useQuestionnaire } from "hooks/questionnaire";
import { getQuestionById } from "lib/questionnaire";
import { QuestionModel } from "models/question";
import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from "react";

interface QuestionContextInterface {
  question: QuestionModel;
}

export const QuestionContext = createContext<
  QuestionContextInterface | undefined
>(undefined);

interface QuestionProviderProps {
  questionId?: number;
}

const QuestionProvider: FunctionComponent<QuestionProviderProps> = ({
  children,
  questionId,
}) => {
  const questionnaire = useQuestionnaire();

  // a small utility to load the question
  const load = () => {
    return getQuestionById(
      questionnaire,
      questionId || questionnaire.initialQuestion
    );
  };

  // internal state for the chosen question
  const [question, setQuestion] = useState<QuestionModel | undefined>(load());

  useEffect(() => {
    const q = load();
    setQuestion(q);
  }, [questionId]); // make sure the questionId changes the state!

  // sanity check, should not happen
  if (!question) {
    console.warn(`No question with id = ${questionId}`);
    return null;
  }

  return (
    <QuestionContext.Provider value={{ question }}>
      {children}
    </QuestionContext.Provider>
  );
};

export default QuestionProvider;
