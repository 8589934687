import { ThemeProps } from "models/theme";
import styled from "styled-components";
import { ColorCode } from "theme/colors";

export const StyledProgress = styled.div`
  height: 2px;
  display: inline-block;
  width: 140px;
  margin-left: 1rem;
`;

interface OuterProps extends ThemeProps {}

export const StyledOuter = styled.div<OuterProps>`
  height: 2px;
  background-color: ${ColorCode.MERCURY};
  position: relative;
`;

interface Props {
  $progress: number;
}

export const StyledInner = styled.div<Props>`
  height: 2px;
  max-width: 100%;
  background-color: ${ColorCode.BLUE};
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.25s;
  width: ${(props) => props.$progress}%;
`;
