import { FunctionComponent } from "react";
import { StyledSectionWrapper } from "./section-wrapper.styles";

interface SectionWrapperProps {
  gray?: boolean;
}

const SectionWrapper: FunctionComponent<SectionWrapperProps> = ({
  children,
  gray,
}) => {
  return <StyledSectionWrapper $gray={gray}>{children}</StyledSectionWrapper>;
};

export default SectionWrapper;
