import { QuestionnaireModel } from "models/questionnaire";
import Graph from "node-dijkstra";
import { getFollowUpIds } from "./question";
import { getAllQuestions, getQuestionById } from "./questionnaire";

export const generateGraph = (questionnaire: QuestionnaireModel) => {
  const { progress } = questionnaire;
  const { end } = progress;

  const graph = new Graph();

  // iterate questions
  const questions = getAllQuestions(questionnaire);
  questions.forEach((question) => {
    // calculate neighbors
    const neighbors: { [key: number]: 1 } = {};

    // if content, add something else
    const followUpIds = getFollowUpIds(question);
    followUpIds.forEach((id) => {
      neighbors[id] = 1;
    });

    // for debug purposes
    if (followUpIds.length === 0 && question.id < end) {
      console.warn(`No followups for ${question.id}`);
    }

    // if points to self, let developer know
    if (followUpIds.includes(question.id)) {
      console.warn(`Question ${question.id} has itself as a followup!`);
    }

    // add to graph
    graph.addNode(question.id.toString(), neighbors);
  });

  return graph;
};

export const calculateCurrentProgress = (
  questionnaire: QuestionnaireModel,
  currentQuestionId: number
) => {
  const questions = getAllQuestions(questionnaire);
  const passedQuestions = questions.filter((q) => q.id <= currentQuestionId);

  let currentProgress = 0;
  passedQuestions.forEach((question) => {
    const { weight = 1 } = question;
    currentProgress += weight;
  });

  return currentProgress;
};

export const calculateRemainingProgress = (
  graph: Graph,
  currentQuestionId: number,
  questionnaire: QuestionnaireModel
) => {
  const { progress } = questionnaire;
  const { end } = progress;

  // graph.path return undefined/null if nothing left, default to [] in that case
  const remainingQuestions =
    graph.path(currentQuestionId.toString(), end.toString()) || [];

  let remainingProgress = 0;
  (remainingQuestions as string[]).forEach((id, index) => {
    if (index === 0) {
      // skip the first question as it is the current question
      return;
    }

    // add question with weight
    const question = getQuestionById(questionnaire, parseInt(id, 10));
    if (question) {
      const { weight = 1 } = question;
      remainingProgress += weight;
    }
  });

  return remainingProgress;
};
