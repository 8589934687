import { getType, MediaExtension, MediaUrls } from "lib/media";
import { FunctionComponent } from "react";
import { range } from "theme/devices";
import { StyledImage, StyledPicture } from "./image.styles";

interface ImageProps {
  className?: string;
  urls: MediaUrls;
  extension?: MediaExtension;
  alt?: string;
  css?: any;
}

const Image: FunctionComponent<ImageProps> = ({
  className,
  urls,
  alt,
  extension = "png",
  css,
}) => {
  const { mobile, mobileWebp, tablet, tabletWebp, desktop, desktopWebp } = urls;

  return (
    <StyledPicture>
      {/* mobile image */}
      {mobileWebp && generateSourceTag(range.mobile, mobileWebp, "webp")}
      {generateSourceTag(range.mobile, mobile, extension)}

      {/* tablet image */}
      {tabletWebp && generateSourceTag(range.tablet, tabletWebp, "webp")}
      {generateSourceTag(range.tablet, tablet, extension)}

      {/* desktop image */}
      {desktopWebp && generateSourceTag(range.desktop, desktopWebp, "webp")}
      {generateSourceTag(range.desktop, desktop, extension)}

      {/* fallback image */}
      <StyledImage src={desktop} alt={alt} css={css} className={className} />
    </StyledPicture>
  );
};

export default Image;

// generates a <source /> tag
const generateSourceTag = (
  media: string,
  srcSet: string,
  extension: MediaExtension,
) => {
  return <source media={media} srcSet={srcSet} type={getType(extension)} />;
};
