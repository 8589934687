import { QuestionContext } from "providers/question";
import { useContext } from "react";

export const useQuestion = () => {
  const context = useContext(QuestionContext);
  if (!context) {
    throw new Error("No QuestionProvider found!");
  }
  return context.question;
};
