import React, { FunctionComponent } from "react";
import { StyledBlock } from "./block.styles";

interface BlockProps {
  className?: string;
  order?: number;
}

const Block: FunctionComponent<BlockProps> = ({
  className,
  children,
  order = 1,
}) => {
  return (
    <StyledBlock className={className} $order={order}>
      {children}
    </StyledBlock>
  );
};

export default Block;
