import React, { FunctionComponent } from "react";
import { StyledCollapse } from "./collapse.styles";
import { useTheme } from "hooks/theme";

interface CollapseProps {
  collapsed: boolean;
}

const Collapse: FunctionComponent<CollapseProps> = ({
  children,
  collapsed,
}) => {
  const theme = useTheme();
  return (
    <StyledCollapse $theme={theme} $collapsed={collapsed}>
      {children}
    </StyledCollapse>
  );
};

export default Collapse;
